import React from 'react';
import styles from './LoaderIcon.module.scss';

const LoaderIcon = () => (
  <svg
    className={styles.loading}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M28.732 11.147C29.3053 11.6289 30.0336 11.8938 30.7808 11.8938C31.7289 11.8938 32.6213 11.478 33.2307 10.7529C34.3625 9.40416 34.1858 7.3857 32.837 6.25304C32.2629 5.77119 31.5345 5.50586 30.7865 5.50586C29.8388 5.50586 28.9465 5.92169 28.3379 6.64672C27.2056 7.99591 27.3828 10.014 28.732 11.147Z"
      fill="white"
    />
    <path
      d="M36.2702 14.8026C36.0722 14.8026 35.872 14.8202 35.6764 14.8546C33.8372 15.179 32.6036 16.9403 32.928 18.7804C33.2134 20.4023 34.6139 21.5793 36.2575 21.5793C36.4552 21.5793 36.6561 21.5616 36.8525 21.5276C38.6918 21.2028 39.9249 19.4419 39.6006 17.6026C39.3143 15.9803 37.9134 14.8026 36.2702 14.8026Z"
      fill="white"
    />
    <path
      d="M36.1526 25.8819C35.608 25.567 34.9904 25.4013 34.367 25.4013C33.0884 25.4013 31.8975 26.0882 31.259 27.1938C30.7812 28.0217 30.6533 28.9863 30.9014 29.9106C31.1495 30.8349 31.7429 31.608 32.57 32.0857C33.115 32.4011 33.7326 32.5672 34.3568 32.5672C35.6346 32.5672 36.8255 31.8807 37.4636 30.7755C38.4503 29.0646 37.8622 26.8694 36.1526 25.8819Z"
      fill="white"
    />
    <path
      d="M25.9651 32.2518C25.5264 32.2518 25.0917 32.3289 24.6734 32.4806C23.7261 32.8255 22.9703 33.519 22.5438 34.4331C22.1177 35.3471 22.073 36.3724 22.4175 37.3201C22.9588 38.8071 24.3851 39.8064 25.9664 39.8064C26.4052 39.8064 26.8399 39.7293 27.2569 39.5772C28.2034 39.2323 28.9588 38.5393 29.3857 37.6235C29.8122 36.709 29.8573 35.6838 29.5136 34.7378C28.9731 33.2512 27.5464 32.2518 25.9651 32.2518Z"
      fill="white"
    />
    <path
      d="M16.3637 32.2973C15.9233 32.1374 15.4665 32.0562 15.0063 32.0562C13.3438 32.0562 11.8446 33.1068 11.2758 34.6705C10.9124 35.6674 10.9596 36.7455 11.4078 37.7064C11.8556 38.6676 12.6508 39.3964 13.6477 39.7593C14.0873 39.9196 14.5446 40 15.0059 40C16.6693 40 18.1681 38.9494 18.7365 37.3869C19.099 36.392 19.0515 35.3139 18.6028 34.3523C18.1538 33.3894 17.359 32.6598 16.3637 32.2973Z"
      fill="white"
    />
    <path
      d="M10.2177 26.9018C9.47548 25.617 8.09226 24.8194 6.60692 24.8194C5.88065 24.8194 5.16259 25.0129 4.529 25.3775C3.56529 25.934 2.87634 26.8329 2.58887 27.9061C2.30058 28.9805 2.44821 30.1029 3.0047 31.0662C3.74696 32.3515 5.1306 33.1495 6.61594 33.1495C7.34179 33.1495 8.05986 32.9559 8.69303 32.5909C10.6811 31.4431 11.3652 28.8907 10.2177 26.9018Z"
      fill="white"
    />
    <path
      d="M4.71395 22.5512C6.82918 22.5512 8.63234 21.0359 9.0006 18.9481C9.20236 17.8011 8.94606 16.6447 8.27803 15.692C7.61081 14.7382 6.61184 14.1017 5.46441 13.8991C5.21016 13.8548 4.95303 13.8323 4.70165 13.8323C2.58641 13.8323 0.783254 15.3471 0.414995 17.4349C0.213232 18.5811 0.469537 19.7375 1.13757 20.6914C1.80519 21.6449 2.80458 22.2817 3.95119 22.4839C4.20421 22.5286 4.46092 22.5512 4.71395 22.5512Z"
      fill="white"
    />
    <path
      d="M10.1903 13.2537C11.2561 13.2537 12.2944 12.8751 13.1142 12.1882C14.0443 11.4078 14.6147 10.31 14.7209 9.097C14.8271 7.88396 14.4556 6.70372 13.6748 5.77364C12.8078 4.74022 11.5353 4.14764 10.1845 4.14764C9.11705 4.14764 8.07871 4.52575 7.26017 5.21264C6.32886 5.99427 5.7572 7.09208 5.65139 8.30348C5.54518 9.51406 5.91713 10.6947 6.69917 11.6268C7.56733 12.6607 8.83942 13.2537 10.1903 13.2537Z"
      fill="white"
    />
    <path
      d="M20.4859 9.90445C23.221 9.90445 25.4381 7.68726 25.4381 4.95222C25.4381 2.21719 23.221 0 20.4859 0C17.7509 0 15.5337 2.21719 15.5337 4.95222C15.5337 7.68726 17.7509 9.90445 20.4859 9.90445Z"
      fill="white"
    />
  </svg>
);

export default LoaderIcon;
